<footer class="footer pt-5 ">
  <div class="container">
    <div class="footer-column">
      <h3>Onesb Bangalore :</h3>
      <p style="font-size: 8px;">ONESB - One School of Business was founded with a quest to educate and prepare a whole new generation of students to excel in the technologically advanced, globally complex, dynamic, fast paced world of business situated in Bangalore - One of the world’s top start-up hubs</p>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3877.6246004068!2d77.65980131535795!3d12.84640859094405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae6d5b6bb14d5d%3A0x289d9d2a7dcbdb92!2sOnesb%20-%20National%20School%20of%20Business!5e0!3m2!1sen!2sin!4v1590135679990!5m2!1sen!2sin"
        width="200" height="150" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
        tabindex="0">
      </iframe>
    </div>
    <div class="footer-column">
      <h3>Contact Us</h3>
      <p>#23/1, Nagawara, Bangalore - 560 045, Karnataka, India</p>
      <p>Admission Hotline: +91 8971080186</p>
      <p>Toll Free Number: 08025443427</p>
      <div class="social-icons" style="margin-top: 50px;">
        <a href="#"><img height="20px" width="20px" src="https://img.icons8.com/ios-glyphs/30/FFFFFF/twitter.png"
            alt="Twitter"></a>
        <a href="#"><img height="20px" width="20px" src="https://img.icons8.com/ios-glyphs/30/FFFFFF/facebook.png"
            alt="Facebook"></a>
        <a href="#"><img height="20px" width="20px" src="https://img.icons8.com/ios-glyphs/30/FFFFFF/linkedin.png"
            alt="LinkedIn"></a>
        <a href="#"><img height="20px" width="20px" src="https://img.icons8.com/ios-glyphs/30/FFFFFF/youtube.png"
            alt="YouTube"></a>
        <a href="#"><img height="20px" width="20px" src="https://img.icons8.com/ios-glyphs/30/FFFFFF/instagram.png"
            alt="Instagram"></a>
      </div>
    </div>
    <div class="footer-column">
      <h3>USEFUL LINKS</h3>
      <ul style="display: flex;flex-direction: column;">
        <li><a href="/grievance">Grievance Cell</a></li>
        <li><a href="/mandatory-disclosure">Mandatory Disclosures</a></li>
        <li><a href="/feedback">Feedback Facility</a></li>
        <li><a href="/aicte-approval">AICTE APPROVAL</a></li>
        <li><a href="/alumini">Alumni</a></li>
        <li><a href="/commitee">Committee</a></li>
        <li><a href="/contact">Contact</a></li>
      </ul>
    </div>
    <div class="footer-column">
      <h3>LIBRARY RESOURCES</h3>
      <ul style="display: flex;flex-direction: column;">
        <li><a href="/library">Onesb E-Library</a></li>
        <li><a href="/sage-journals">Sage Journals</a></li>
        <li><a href="/mba/blogs">Blogs</a></li>
      </ul>
    </div>
  </div>
  <div class="footer-bottom mt-3">
    <p style="color: white;">&copy; 2024 Onesb Bangalore.</p>
  </div>
</footer>